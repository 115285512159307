
import Vue from 'vue'
import { TranslateResult } from 'vue-i18n'
import { HymnResultsApi } from '~/apiclient/apihymns'
import {
  customSitePath,
  foundationsPsalterRootUrl,
} from '~/assets/ts/utils/urls'
import SearchInput from '~/components/_general/SearchInput.vue'
import SaLink from '~/components/_general/SaLink.vue'
import InlineIcon from '~/components/_general/InlineIcon.vue'
import ScrollList from '~/components/_general/ScrollList.vue'
import HymnElement from '~/components/_general/HymnElement.vue'
import SiteImageHeader from '~/components/site/ImageHeader.vue'

export default Vue.extend({
  name: 'HymnalSearchLayout',
  components: {
    SiteImageHeader,
    HymnElement,
    ScrollList,
    InlineIcon,
    SaLink,
    SearchInput,
  },
  props: {
    isPsalter: {
      type: Boolean,
      required: true,
    },
    displayResults: {
      type: Boolean,
    },
    foundations: {
      type: Boolean,
    },
  },
  data() {
    return {
      searchQuery: this.$route.params.keyword,
      searching: false,
      pageNum: 1,
      searchResultCount: 0,
      hymnPojos: [] as HymnResultsApi[],
    }
  },
  async fetch() {
    if (this.displayResults && this.searchQuery !== '') {
      await this.getSearchHymns()
    }
  },
  computed: {
    title(): TranslateResult {
      return this.$t('Hymnal & Psalter')
    },
    backUrl(): string {
      if (this.foundations) {
        return customSitePath(this, 'psalter', foundationsPsalterRootUrl)
      }
      return this.isPsalter ? 'psalter' : 'hymnal'
    },
    searchHymns(): HymnResultsApi[] {
      return this.hymnPojos
    },
    endOfList(): boolean {
      return this.hymnPojos.length >= this.searchResultCount
    },
    searchPlaceholder(): TranslateResult {
      return this.isPsalter
        ? this.$t(`Search All Psalms`)
        : this.$t('Search All Hymns')
    },
  },
  methods: {
    async getSearchHymns() {
      this.searching = true
      const { results, totalCount } = await this.$apiClient.getHymns({
        psalterOnly: this.isPsalter,
        excludePsalter: !this.isPsalter,
        searchKeywords: this.searchQuery,
        page: this.pageNum,
        pageSize: 15,
      })
      this.searchResultCount = totalCount
      this.searching = false
      if (!results || !results.length) {
        return
      }
      results.forEach((h) => {
        this.hymnPojos.push(h)
      })
      this.pageNum++
    },
    async searchInput() {
      let base = `/${this.isPsalter ? 'psalter' : 'hymnal'}/`
      if (this.foundations) {
        base = customSitePath(this, 'psalter', foundationsPsalterRootUrl)
      }
      await this.$router.push(`${base}search/${this.searchQuery}`)
    },
  },
})
